import { useContext } from 'react';
import dynamic from 'next/dynamic';

import { GridBox, Row, Cell, CellContent } from './style';
import { MakeReactKey, HandleType, HandleLink } from '../../lib/utilities';
import { IGridEffect } from '../GridImage';
import { Colors, IGrid } from '../common';
import { MenuContext } from '../Layout';

const Loader = dynamic(() => import('../../components/Loader'), {
  loading: () => <p>....Loading.....</p>,
});

export interface IColors {
  colors?: Colors;
}
export interface GridProps {
  data: IGrid[];
}

export interface ICellPosition {
  position?: string;
}

const Grid = ({ data }: GridProps) => {

  const GridImage = dynamic(() => import('../GridImage').then((mod)=>mod.GridImage), {
    loading: () => <Loader isLoading />,
  });

  const Button = dynamic(() => import('../Button'), {
    loading: () => <Loader isLoading />,
  });
  
  const menus = useContext(MenuContext);
  return (
    <GridBox>
      {data.map(({ gridImage, gridTitle, gridContent, gridLink }, index) => {
        let color: Colors = Colors.Blue;
        let effect: IGridEffect = {
          initialWidth: 0,
          initialHeight: 0,
          width: 0,
          height: 0,
        };
        if (index === 0) {
          effect.initialWidth = '45%';
          effect.initialHeight = '30%';
          effect.top = '100px';
          effect.left = '200px';
        } else if (index === 1) {
          color = Colors.Red;
          effect.initialWidth = '40%';
          effect.initialHeight = '30%';
          effect.top = '100px';
          effect.right = '100px';
        } else if (index === 2) {
          color = Colors.Green;
          effect.initialWidth = '45%';
          effect.initialHeight = '20%';
          effect.top = '100px';
          effect.left = '200px';
        } else {
          effect.initialWidth = '10%';
          effect.initialHeight = '30%';
          effect.top = '175px';
          effect.right = '50px';
        }
        return (
          <Row key={MakeReactKey(gridTitle)} colors={color}>
            {index % 2 === 0 ? (
              <>
                <Cell position="right">
                  <CellContent>
                    <h2>{gridTitle}</h2>
                    <p>{gridContent}</p>
                    {gridLink.length > 0 && gridLink[0]?.id !== 0 ? 
                    <Button
                      label="Learn More"
                      type={HandleType(gridLink[0])}
                      color={color}
                      url={HandleLink(gridLink[0], menus)}
                    />
                    : "" }
                  </CellContent>
                </Cell>
                <Cell>
                  <GridImage data={gridImage} colors={color} effect={effect} priority={true} />
                </Cell>
              </>
            ) : (
              <>
                <Cell>
                  <GridImage data={gridImage} colors={color} effect={effect} />
                </Cell>
                <Cell position="left">
                  <CellContent>
                    <h2>{gridTitle}</h2>
                    <p>{gridContent}</p>
                    {gridLink.length > 0 && gridLink[0]?.id !== 0 ? 
                    <Button
                      label="Learn More"
                      type={HandleType(gridLink[0])}
                      color={color}
                      url={HandleLink(gridLink[0], menus)}
                    /> : ""}
                  </CellContent>
                </Cell>
              </>
            )}
          </Row>
        );
      })}
    </GridBox>
  );
};

export default Grid;
